.form form {
  font-size: 1.2em;
  input[type="text"],
  input[type="number"],
  input[type="password"],
  select {
    display: block;
    width: 85%;
    padding: 9px;
    font-size: 1em;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 50px;
    border: 1px solid;
  }
  textarea {
    display: block;
    width: 85%;
    padding: 9px;
    font-size: 1em;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid;
  }
  input[type="radio"] {
    zoom: 2;
    margin-bottom: 16px;
  }
  label {
    color: $dark-grey;
    display: block;
  }
  .button {
    display: block;
    width: 85%;
    padding: 9px;
    font-size: 1.2em;
    margin-bottom: 16px;
    background-color: $yellow;
    cursor: pointer;
  }
  .button:hover {
    display: block;
    width: 85%;
    padding: 9px;
    font-size: 1.2em;
    margin-bottom: 16px;
    background-color: #cc8501;
  }
  .error {
    color: red;
  }
}

.precio-tachado {
  color: $light-red;
  text-decoration: line-through;
}

.clock-countdown {
  text-align: center;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 2px;
  border-radius: 10px;
  font-size: 1.4em;
  h1 {
    font-size: 1.8em;
  }
}

.Montserrat {
  font-family: "Montserrat" !important;
  font-weight: 700;
}

.vertical-align {
  margin-top: auto;
  margin-bottom: auto;
}

.profesor-photo {
  width: 100%;
  display: block;
  min-height: 244px;
}

.hr {
  border-top: 1px solid $dark-grey;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading {
  color: $blue;
  font-size: 1.5em;
}

.img-fluid {
  padding-top: 5%;
  width: 100%;
  height: auto;
}

.center {
  align-items: center;
  text-align: center;
}

.error-input {
  color: red;
  background-color: #ffcaf1;
  text-align: center;
  border-radius: 3px;
  width: 85%;
}

.transferencia-bancaria {
  width: 85%;
  background: #56ff00;
  padding: 8px;
  text-align: center;
  margin-bottom: 15px;
  display: block;
  border-radius: 8px;
}

.mercadopago-button {
  display: block;
  margin-bottom: 20px;
}

.mercadopago-button:after {
  content: " con Mercado Pago";
}

.cien {
  width: 85% !important;
}
