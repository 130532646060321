.navbar {
  border-top: solid #dedfe0 1px;
  border-bottom: solid #dedfe0 1px;
  display: inline-block;
  font-family: "Poppins";
  ul {
    width: 75%;
    transform: translateY(10px);
    align-items: center;
    text-align: center;
  }
  li {
    display: inline-block;
    padding-left: 70px;
  }
  .button-container {
    width: 25%;
  }
}

.btn {
  font-family: "Poppins";
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 5em;
  border: none;
  line-height: 1;
  padding: 10px 35px;
  text-align: center;
  min-width: 170px;
  font-weight: 300;
  margin-bottom: 4px;
  white-space: pre-wrap;
  border-width: 2px;
  &-outline {
    background-color: transparent;
    color: #2c2b26;
    border: 1px solid $blue;
  }
  &-outline-white {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  &-blue {
    background-color: $blue;
    color: white;
  }
  &-lichess {
    background-color: #262421;
    color: white;
  }
  &-youtube {
    background-color: #c00;
    color: white;
  }
  &-orange {
    background-color: $orange;
    color: white;
  }
  &-green {
    background-color: $green;
    color: white;
  }
  &-block {
    width: 85%;
    font-size: 0.8em;
    margin-top: 20px;
    margin-bottom: 20px;
    &-big {
      font-size: 2em;
    }
  }
}

.btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

.btn-blue:active {
  outline: none;
  border: none;
}

.btn:focus {
  outline: 0;
  &-outline-white {
    outline: 1px;
  }
}

.vl {
  border-left: 1px solid #dedfe0;
  height: 220px;
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
}

.magic-input {
  border: solid rgb(40, 40, 40) 1px;
  border-radius: 30px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 35px;
  font-family: "Montserrat";
  input {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.2em;
    margin-left: 10px;
    min-width: 200px;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  button {
    font-size: 1.1em;
    font-family: "Montserrat";
    color: white;
    font-weight: bold;
    background-color: $blue;
    border: none;
    padding: 12px;
    margin: 0;
    border-radius: 30px;
  }
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  button:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
}

.copyright {
  font-family: "Poppins";
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 25px;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.topbar-icon:hover,
.footer-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.bg {
  &-white {
    background-color: white;
  }
  &-light {
    background-color: $light;
  }
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid $orange;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.grupo-amateur {
  &-img {
    display: block;
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
  }
}

.table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

.cipolletti-icons {
  svg {
    transform: translate(6px, 1px);
  }
}
