.topbar {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  &-icon {
    margin-left: 10px;
  }
}

.ordenador {
  display: block;
}

.mobile {
  display: none !important;
}

.header {
  position: relative;
  height: 88vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./images/portada1.jpg");
  background-size: cover;
  &-inline {
    display: inline-block;
  }
  &-titulo {
    display: inline-block;
    transform: translateY(-20px);
    h2 {
      font-size: 3.1em;
      padding: 0;
      margin: 0;
    }
    .superior {
      font-family: "Montserrat";
      color: white;
      font-weight: 200;
    }
    .inferior {
      color: #007bff;
      font-weight: 700;
    }
    .subtitle {
      font-family: "Poppins";
      font-weight: 300;
      color: #ffffff;
    }
  }
  &-text {
    z-index: 2;
    float: left;
    width: 100%;
    margin-left: 8% !important;
    img {
      display: inline-block;
      height: 240px;
      width: auto;
    }
  }
  &-button-container {
    margin-top: 30px;
  }
}

.header::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(3, 18, 33, 0.7);
}

.about-us {
  background: linear-gradient(180deg, $dark-grey 50%, #efefef 50%);
  h6 {
    font-family: "Poppins";
    font-size: 1.1em;
    color: #ffffff;
    padding: 5%;
    margin: 0;
    margin-top: 60px;
    font-weight: 400;
  }
  h4 {
    font-family: "Montserrat";
    font-size: 1.5em;
    color: $blue;
    padding: 0;
    margin: 0;
    margin-top: 60px;
  }
  h2 {
    padding: 0;
    margin: 0;
    font-family: "Montserrat";
    color: $blue;
    font-size: 3.5em;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .card {
    transform: translateY(60px);
    &-description {
      padding: 10px;
      transform: translateY(-50px);
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      background-color: #ffffff;
      -webkit-box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: -2px 6px 5px 0px rgba(0, 0, 0, 0.75);
      h5 {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1.2em;
        padding: 0;
        margin: 0;
        color: $blue;
      }
      h3 {
        padding: 0;
        margin: 0;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 1.4em;
        color: $dark-grey;
      }
    }
  }
  .pasion {
    text-align: center;
    margin-top: 85px;
    margin-bottom: 85px;
    blockquote {
      font-family: "Poppins";
      font-style: italic;
      font-weight: bold;
    }
  }
}

.youtube {
  min-height: 550px;
  background: linear-gradient(90deg, #ffffff 80%, $dark-grey 20%);
  img {
    height: auto;
    width: 92%;
    display: block;
    padding-top: 90px;
    padding-bottom: 60px;
  }
  .va {
    max-width: 95% !important;
    transform: translateY(30%);
    h1 {
      font-size: 5.2em;
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-family: "Poppins";
      color: $blue;
    }
    h4 {
      font-family: "Montserrat";
    }
    h3 {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
      color: #22211c;
    }
    h6 {
      color: #5b5f66;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.peones {
  background-color: $light;
  margin: 0;
  padding: 0;
  &-title {
    font-family: "Montserrat";
    text-align: center;
    margin: 0;
    padding: 0;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 3.3em;
      color: #22211c;
    }
    h4 {
      margin: 0;
      padding: 0;
      margin-top: 60px;
      font-size: 1.6em;
      color: #22211c;
    }
    p {
      margin-top: 22px;
      padding-left: 1%;
      padding-right: 7%;
      font-size: 1.2em;
    }
  }
  &-campeon {
    background-color: #ffffff;
    text-align: center;
    padding: 15px;
    margin-top: auto;
    margin-bottom: auto;
    /*     transform: translateY(100px); */
    h3 {
      font-size: 1.8em;
    }
    p {
      font-size: 1.2em;
      color: #22211c;
    }
    div {
      border-radius: 30px;
      background-color: $blue;
      width: 30%;
      margin-left: auto;
      margin-right: auto;
      padding: 8px;
      color: #ffffff;
      font-size: 1.5em;
    }
  }
  .outer {
    display: inline-block;
    display: inline;
    zoom: 1;
    position: relative;
    clip: auto;
    overflow: hidden;
    width: 70%;
  }
  .containerDot {
    position: relative;
    text-align: right;
    white-space: nowrap;
    margin-top: 30px;
    font-size: 1.8em;
  }
  .filler {
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #333;
    height: 50%;
  }
  .label {
    background: $light;
    float: left;
    margin-right: 20px;
    padding-right: 4px;
    position: relative;
  }
  .text {
    background: $light;
    padding-left: 4px;
    position: relative;
  }
}

.gruposDeEstudio {
  background-color: $dark-grey;
  font-family: "Montserrat";
  h4 {
    font-size: 1.5em;
    color: $blue;
    padding: 0;
    margin: 0;
    margin-top: 60px;
    text-align: center;
  }
  h2 {
    padding: 0;
    margin: 0;
    color: $blue;
    font-size: 3.5em;
    text-align: center;
  }
  p {
    color: #ffffff;
    line-height: 1.5rem;
  }
  .card {
    margin-top: 30px;
    padding: 50px;
    text-align: center;
    background-color: #ffffff;
    border: 20px solid $dark-grey;
    margin-bottom: auto;
    h5 {
      padding: 0;
      margin: 0;
      font-size: 2.1em;
    }
    span {
      font-size: 0.6em;
      font-family: "Montserrat";
      font-weight: 200;
    }
    h6 {
      padding: 8px;
      margin: 8px;
      font-size: 1em;
      font-family: "Poppins";
      font-weight: 500;
    }
    hr {
      width: 100%;
    }
    transition: transform 350ms;
  }
  .card:hover {
    transform: translateY(-5px);
  }
}

.team {
  font-family: "Montserrat";
  background-color: $light;
  h4 {
    font-size: 1.5em;
    color: $blue;
    padding: 0;
    margin: 0;
    margin-top: 60px;
  }
  h2 {
    padding: 0;
    margin: 0;
    color: $blue;
    font-size: 3.5em;
  }
  p {
    color: $dark-grey;
    line-height: 1.5rem;
  }
  .card {
    padding: 0;
    border: 15px solid $light;
    text-align: center;
    background-color: white;
    h4 {
      color: $dark-grey;
    }
    img {
      width: 100%;
    }
  }
}

.live {
  &-title {
    font-family: "Montserrat";
    color: $blue;
  }
  &-description {
    font-family: "Poppins";
    color: $dark-grey;
  }
}

.footer {
  background-color: $dark-grey;
  color: white;
  font-family: "Montserrat";
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  &-icon {
    margin-left: 10px;
  }
  &-top {
    margin-bottom: 45px;
  }
  hr {
    border: solid rgb(40, 40, 40) 1px;
  }
}

@media only screen and (max-width: 991px) {
  .topbar-logo {
    margin-top: 40px;
  }
  .vl {
    display: none !important;
  }
  .header-logo {
    width: 40% !important;
    margin-bottom: 30px;
    margin-top: 35px;
    height: auto !important;
  }
  .header-inline {
    text-align: center;
    margin-left: 0 !important;
  }
  .header-text {
    margin-left: 0 !important;
  }
  .about-us {
    background: linear-gradient(180deg, $dark-grey 30%, #efefef 70%);
  }
  .youtube {
    background: white !important;
  }
  .ordenador {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 431px) {
  .btn-block-in-mobile {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .card {
    width: 95% !important;
  }
  .magic-input button {
    font-size: 1;
  }
  .magic-input input {
    min-width: 80px !important;
  }
}

@media only screen and (max-width: 644px) {
  a {
    display: block;
    margin-left: 0 !important;
  }
  .header-button-container {
    width: 100%;
    max-width: 80%;
    margin: auto;
  }
  .row {
    margin-left: auto !important;
  }
}
@media only screen and (max-width: 432px) {
/*   .header-button-container {
    margin: 0 !important;
    transform: translateY(-35px);
  } */
}

@media only screen and (max-width: 562px) {
  .iframe-magistral {
    min-height: 100vh !important;
  }
}

.w-70 {
  width: 70%;
  display: inline;
}
.w-30 {
  width: 30%;
  display: inline;
}
