@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500&display=swap");

$dark-grey: #343a40;
$blue: #007bff;
$light: #efefef;

$green: #68ca01;
$yellow: #ffcc07;
$dark-blue: #000034;
$light-blue: #007cfd;
$dark-red: #920d0d;
$light-red: #e82727;
$orange: #ff7846;

$primary-color: #04063c;
$secondary-color: #04063c;
$terciary-color: #6dc521;
$forty-color: #e8e8e8;

$black: #000000;
$white: #ffffff;

html {
  overflow-x: hidden !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "PT Sans", "Poppins", Arial, sans-serif;
}

body::before {
  content: "";
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  z-index: 100;
}

a {
  text-decoration: none;
}

.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 0 !important;
    padding: 0 !important;
  }
}
