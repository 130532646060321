.grupo-de-estudio {
  width: 100% !important;
  overflow-x: hidden;

  @media (min-width: 769px) {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;

    table {
      width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    table {
      font-size: 0.9em !important;
    }

    .input-group {
      width: 85% !important;
    }
  }

  @media (max-width: 419px) {
    .card-radio {
      display: block !important;
    }

    .label-radio {
      display: inline-block !important;
    }
  }

  &-header {
    &-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      h1 {
        margin: auto;
        background-color: $white;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
        color: #fff;
      }
    }

    &-logo {
      width: auto;
      max-width: 380px;
      margin: auto;
    }
  }

  &-titles {
    h1 {
      position: relative;
      z-index: 1;
      overflow: hidden;
      text-align: center;
      color: $dark-grey;
    }

    h1:before,
    h1:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 50%;
      height: 2px;
      content: "\a0";
      background-color: $dark-grey;
    }

    h1:before {
      margin-left: -50%;
      text-align: right;
    }

    .color {
      background-color: #ccc;
    }
  }

  &-form {
    color: $dark-grey;
    font-size: 1.2em;
    width: 100%;

    .input-group {
      width: 48%;
      display: inline-block;
      padding-top: 2%;
      padding-right: 2%;
      padding-left: 2%;
      padding-bottom: 2%;
      box-sizing: border-box;

      input[type="text"] {
        width: 100%;
        padding: 4px;
      }

      input[type="radio"] {
        zoom: 2;
        padding: 4px;
      }

      .label-radio {
        top: -5px;
        position: relative;
        color: $dark-grey;
      }

      .label-icon {
        width: 22px;
        height: 22px;
      }

      .label-card {
        width: 62px;
        height: 38px;
        margin-left: 9px;
        top: 10px;
        position: relative;
        margin-bottom: 5px;
      }

      .card-radio {
        display: inline-block;
        border: 1px solid $white;
        padding: 10px;
        border-radius: 25%;
        margin-left: 5px;
        margin-top: 5px;
      }
    }

    .cien {
      width: 96% !important;
    }
  }

  .input-error {
    color: #b10808 !important;
    display: block !important;
  }

  &-content {
    padding: 1rem 4rem;

    h3 {
      color: $primary-color;
    }

    p,
    li,
    ul {
      color: $white;
    }

    span {
      color: $terciary-color;
      font-weight: bold;
    }

    .bye-bye {
      margin: 1.5rem 0 1.5rem;
      text-align: center;
      background: $terciary-color;
      border: 1px solid #ddd;
      color: $forty-color;
      border-radius: 4px;
      display: block !important;
      padding: 4%;
    }

    table {
      color: $white;
      font-size: 1.6em;
      width: 100%;
      margin: auto;
      border-collapse: collapse;

      tr {
        border: solid;
        border-width: 1px 0;
      }

      .right {
        float: right;
        text-align: right;
      }

      span {
        color: $secondary-color;
      }
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button {
      box-shadow: 0px 1px 0px 0px #fff6af;
      background: linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
      background-color: #ffec64;
      border-radius: 6px;
      border: 1px solid #ffaa22;
      display: inline-block;
      cursor: pointer;
      color: #333333;
      font-family: Arial;
      font-size: 1.1em;
      font-weight: bold;
      padding: 20px 60px;
      text-decoration: none;
      text-shadow: 0px 1px 0px #ffee66;
    }

    .button:hover {
      background: linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
      background-color: #ffab23;
    }

    .button:active {
      position: relative;
      top: 1px;
    }
  }

  #mlButton {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }

  a {
    font-size: 1.6em;
    color: #0de21d;
  }

  .whatsapp-button {
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 5%;
    margin-bottom: 25px;
    opacity: 1;
    cursor: pointer;
  }

  .whatsapp-button:hover {
    opacity: 0.6;
  }

  .mail-button {
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 5%;
    margin-bottom: 110px;
    opacity: 1;
    cursor: pointer;
  }

  .mail-button:hover {
    opacity: 0.6;
  }
}
