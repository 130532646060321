.peones-page {
  .container {
    width: 85%;
    height: 90vh;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
  }

  .card-left {
    width: 50%;
    background-color: white;
    color: white;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    float: left;
    img {
      display: inline;
      width: 100%;
      float: left;
    }
  }

  .card-right {
    width: 50%;
    background-color: #007bff;
    border-radius: 35px;
    color: white;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    float: right;
    input {
      display: block;
      width: 72%;
    }
    h2 {
      font-size: 2.1em;
      font-family: "Poppins", sans-serif;
      margin: 0;
      padding: 0;
    }
    h5{
      font-family: 'Montserrat', sans-serif;
    }
  }

  .btn {
    margin-top: 55px;
    margin-bottom: 35px;
    background-color: #ffcc00;
    border-radius: 28px;
    border: 1px solid #ffcc00;
    display: inline-block;
    cursor: pointer;
    color: #111111;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    padding: 14px 25px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #2f6627;
    margin-left: auto;
    margin-right: auto;
  }
  .btn:hover {
    opacity: 0.6;
  }
  .btn:active {
    position: relative;
    top: 1px;
  }

  .btn2 {
    padding: 3px;
    margin-top: 15px;
    background-color: #ff6633;
    border-radius: 28px;
    border: 1px solid #ff6633;
    display: block;
    cursor: pointer;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    float: right;
    text-decoration: none;
    text-shadow: 0px 1px 0px #2f6627;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-18px, 12px);
  }

  .btn2:hover {
    opacity: 0.6;
  }
  .btn2:active {
    position: relative;
    top: 1px;
  }

  .ipt {
    margin-top: 15px;
    padding: 8px;
    font-size: 16px;
    border-width: 1px;
    border-color: #cccccc;
    background-color: #ffffff;
    color: #000000;
    border-style: solid;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    margin-left: auto;
    margin-right: auto;
  }
  .ipt:focus {
    outline: none;
  }

  .errors {
    color: red;
    font-size: 0.9em;
    font-weight: bolder;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
  }

  @media (max-width: 992px) {
    .container {
      padding-bottom: 61%;
    }
    .card-left {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
    .card-right {
      width: 100%;
      height: auto;
      min-height: 100%;
      margin-bottom: 35px;
    }
  }
}
